<template>
  <transition name="modal">
    <div class="modal-backdrop" @click.self="close">
      <div class="modal">
        <div class="modal-title center">Mencapai Batas Watu Harian</div>
        <p>{{propsMessage}}
        </p>
        <div class="center mt-5">
          <router-link to="/user/my-classes" class="bg-yellow-200 p-2 rounded-lg hover:bg-yellow transition-all px-10 " >Kelas Saya</router-link>
          <Button
            type="button" class="rounded-lg bg-white relative py-2 px-10 border border-grey-field "
            buttonText="Back"
            style="background-color: #ffff; border: 1px solid #3b82f6; color: #3b82f6"
            @click="close('showModal: False')"
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'video-notif-modal',
  props: {
    close: {
      type: Function
    },
    message: {
      type: String
    }
  },
  computed: {
    propsMessage: {
      get() {
        return this.message
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 999;
  transition: opacity 0.2s ease;
}
.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 250px;
  border-radius: 8px;
  position: relative;
  padding: 30px 40px;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  &-title {
    color: #333333;
    margin-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
  }
  textarea {
    border-radius: 7px 7px 0px 0px;
    width: 100%;
    height: 100%;
    resize: none;
    border: 1px solid #c4c4c4;
    border-bottom: 1px solid #ffffff;
    padding: 10px;
    outline: none;
  }
  button {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    box-sizing: border-box;
    padding: 5px;
    border: none;
    height: 35px;
    width: 50%;
    color: #ffffff;
    background-color: #3b82f6;
    border: 1px solid #3b82f6;
    cursor: cursor;
    outline: none;
    opacity: 1;
    transition: 0.1s;
    margin-bottom: 10px;
  }
  button:hover {
    opacity: 0.8;
  }

  .fadeIn-enter {
    opacity: 0;
  }

  .fadeIn-leave-active {
    opacity: 0;
    transition: all 0.2s step-end;
  }

  .fadeIn-enter .modal,
  .fadeIn-leave-active.modal {
    transform: scale(1.1);
  }

  .font-custom {
    font-size: 18px;
  }

  .center {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    align-items: center;
  }
  .margin {
    margin-top: -10px;
  }
}
</style>
